.invalid-feedback {
  color: #ff5959;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-number {
  width: 100%;
  text-align: right;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}